<template>
  <v-navigation-drawer
    app
    dark
    v-model="drawerCollapsed"
    :color="isPresensi ? '#66BB6A' : '#297bbf'"
  >
    <v-list
      :class="isPresensi ? 'background-green' : 'background-blue'"
      class="pb-2"
    >
      <v-card-actions class="justify-center">
        <v-avatar color="white" class="mt-10" height="100" width="100">
          <v-img :src="profile.photo" contain height="100" />
        </v-avatar>
      </v-card-actions>
      <p class="subtitle-1 font-weight-bold text-center white--text mb-0">
        {{ profile.name }}
      </p>
      <p
        v-if="profile.role !== 'USER'"
        class="caption font-weight-regular text-center white--text mb-0"
      >
        {{ profile.role | snakeToTitle }}
      </p>
      <template v-if="profile.role === 'USER'">
        <p class="caption font-weight-bold text-center white--text mb-0">
          {{ profile.NIP }}
        </p>
        <p class="caption font-weight-regular text-center white--text mb-0">
          {{ profile.nama_jabatan }}
        </p>
        <p class="caption font-weight-regular text-center white--text mb-0">
          {{ profile.nama_unit_kerja_2 }}
        </p>
      </template>
    </v-list>
    <v-list expand dense class="mt-1">
      <ListLoader show :loading="navLoading" :size="40" :px="10" :py="10">
        <side-bar-item id="sidebar" :items="navList" />
      </ListLoader>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
const SideBarItem = () => import("@/components/SideBar/Item");
const ListLoader = () => import("@/components/Loader/ListLoader");
import { mapGetters } from "vuex";
import { LIST_MENU } from "@/common/constant";
import { mapSummaryRootField, mapAuthRootField } from "@/store/helpers";
import AuthService from "@/services/resources/auth.service";

export default {
  name: "Sidebar",
  components: {
    SideBarItem,
    ListLoader
  },
  data() {
    return {
      navLoading: false,
      navList: []
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    ...mapSummaryRootField({
      isPresensi: "setting.isPresensi"
    }),
    ...mapAuthRootField({
      profile: "profile"
    }),
    drawerCollapsed: {
      get() {
        return this.$store.state.settings.drawerCollapsed;
      },
      set(value) {
        this.$store.commit("setDrawerCollapsed", value);
      }
    }
  },
  methods: {
    // Service
    async getCurrentUser() {
      try {
        this.loading = true;
        await AuthService.getProfile({ platform: process.env.VUE_APP_MODE })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.profile = this.$_.merge(this.profile, data);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.navList = LIST_MENU(
      this.currentUser.role,
      this.isPresensi,
      this.currentUser.NIP
    );
    this.getCurrentUser();
  }
};
</script>
<style lang="scss">
#sidebar .v-list-item.v-list-item--active {
  border-left: 5px solid #fbd303;
}
.background-blue {
  background-color: #297bbf;
}
.background-green {
  background-color: #66bb6a;
}
</style>
